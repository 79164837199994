// routes
import { Route, Routes, Navigate, useNavigate, useLocation} from 'react-router-dom'
// _helpers
import { history } from './_helpers'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart'
import PrivateRoute from './components/PrivateRoute'
// layouts
import LayoutHomeApp from './layouts/homeApp'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
// public pages 
import PublicHome from './pages/public/Home'
import Login from './pages/public/Login'
import ContactForm from './pages/public/ContactForm'
import Register from './pages/public/Register'
import NotFound from './pages/public/Page404'
import Termo from './pages/public/Termo'
import Error from './pages/public/Error'
// private pages
import AppStations from './pages/app/Stations'
import AppCharge from './pages/app/Charge'
import AppDashBoard from './pages/app/DashBoard'
import AppSupport from './pages/app/Support'

// ----------------------------------------------------------------------

export default function App() {

  history.navigate = useNavigate()
  history.location = useLocation()
  
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Routes>
        <Route path='/' element={<PublicHome/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/contact' element={<ContactForm/>}/>
        <Route path='/app' 
          element={
            <PrivateRoute>
              <LayoutHomeApp />
            </PrivateRoute>
          }>
          <Route path= '' element={ <AppStations /> }/>
          <Route path= 'stations' element={ <AppStations /> }/>
          <Route path= 'stations/:id' element={ <AppStations /> }/>
          <Route path= 'charge' element={ <AppCharge /> }/>
          <Route path= 'history' element={ <AppDashBoard /> }/>
          <Route path= 'support' element={ <AppSupport /> }/>
          <Route path= '*' element={ <NotFound /> }/>
        </Route>
        <Route path='/' element={<LogoOnlyLayout />}>
            <Route path= 'termo' element={ <Termo /> }/>
            <Route path= '404' element={ <NotFound /> }/>
            <Route path= '*' element={ <NotFound /> }/>
            <Route path= 'error' element={ <Error /> }/>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  )
}

import PropTypes from 'prop-types'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Typography, Button} from '@mui/material'
// components
import Page from '../../../components/Page'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  background:'#36209E',
  minHeight: '80px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StationStyle = styled('div')(({ theme }) => ({
  maxWidth: '900px',
  width: '100%',
  minHeight: '50px',
  maxHeight: '437px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

RechargeStationFree.propTypes = {
  station: PropTypes.object, 
  onClickContinue: PropTypes.func,
}

export default function RechargeStationFree({
  station, 
  onClickContinue
}) {
  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StationStyle>
            <Typography variant='h3' sx={{ mb:4, pb:2 }} color='primary'>
              Dados da recarga - {station.name}
            </Typography>
            <Typography variant='h6' sx={{ pb:1 }} >
              Esta estação esta funcionando me modo automatico basta conectar o seu veiculo que a recarga irá iniciar. Neste modo de funcinamento não cobramos pelo serviço.
            </Typography>
          </StationStyle>
          <Button 
            size='medium' 
            variant='contained' 
            color="secondary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onClickContinue}
          >
            Continuar
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}
import PropTypes from 'prop-types'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Typography, TextField, Button} from '@mui/material'
// components
import Page from '../../../components/Page'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '600px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StationStyle = styled('div')(({ theme }) => ({
  maxWidth: '900px',
  width: '100%',
  maxHeight: '437px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

RechargeStationUnlock.propTypes = {
  station: PropTypes.object, 
  setCode: PropTypes.func,
  onCancel: PropTypes.func,
  onClickUnlock: PropTypes.func,
}

let disable = true

export default function RechargeStationUnlock({
  station,
  setCode, 
  onCancel, 
  onClickUnlock,
}) {

  const handleCode = (event) => {
    const conde = event.target.value
    if (conde.length > 2) {
      disable = false
    }
    setCode(conde)
  }

  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StationStyle>
            <Typography variant='h3' sx={{ mb:4, pb:2 }} color='primary'>
              Dados da recarga - {station.name}
            </Typography>
            <Typography variant='h6' sx={{ pb:1 }} >
              Insira o codigo da estação*
            </Typography>
            <TextField 
              id='outlined-basic' 
              type="number"
              placeholder='Digite o código de desbloqueio' 
              variant='outlined'
              onChange={handleCode} 
              sx={{ mb:1, width:'278px'}}
            />
            <Typography variant='h8' sx={{ pb:1 }} >
              *O codigo da estação se encontra logo abaixo da identificação da mesma.
            </Typography>
          </StationStyle>
          <Button 
            size='medium' 
            variant='contained' 
            color="secondary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onClickUnlock}
            disabled={disable}
          >
            Desbloquear
          </Button>

          <Button 
            size='medium' 
            variant='outlined' 
            color="primary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onCancel}
            >
            X Cancelar
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}
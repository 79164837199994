import PropTypes from 'prop-types'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Typography, Select, MenuItem, Button} from '@mui/material'
// components
import Page from '../../../components/Page'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '600px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StationStyle = styled('div')(({ theme }) => ({
  maxWidth: '900px',
  width: '100%',
  maxHeight: '437px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

RechargeStationSelect.propTypes = {
  stations: PropTypes.array,
  station: PropTypes.object, 
  setStation: PropTypes.func,
  onCancel: PropTypes.func,
  onClickContinue: PropTypes.func,
  error: PropTypes.object,
}

export default function RechargeStationSelect({
  stations,
  station, 
  setStation, 
  onClickContinue,
  onCancel, 
  error,
}) {
  const handleSelectStation = (event) => {
    const station = stations.find(sto => sto.uuid === event.target.value)
    setStation(station)
  }

  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StationStyle>
            <Typography variant='h3' sx={{ mb:4, pb:2 }} color='primary'>
              Dados da recarga
            </Typography>
            <Typography variant='h6' sx={{ pb:1 }} >
              Selecione a estação
            </Typography>
            <Select
              labelId='select-station'
              id='select-station'
              value={station?.uuid || 0} 
              onChange={handleSelectStation}
              sx={{ mb:4, width:'278px'}}
              error={error}
            >
              <MenuItem value={0}>Selecione a estação</MenuItem>
              {stations.map((station) => (
                <MenuItem
                  key={`${station.group}-${station.uuid}`}
                  value={`${station.uuid}`}
                >
                  {station.name}
                </MenuItem>
              ))}
            </Select>
          </StationStyle>
          <Button 
            size='medium' 
            variant='contained' 
            color="secondary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onClickContinue}
            disabled={!station}
          >
            Continuar
          </Button>

          <Button 
            size='medium' 
            variant='outlined' 
            color="primary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onCancel}
            >
            X Cancelar
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}
import PropTypes from 'prop-types'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Typography, Button} from '@mui/material'
// components
import Page from '../../../components/Page'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '450px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StationStyle = styled('div')(({ theme }) => ({
  maxWidth: '900px',
  width: '100%',
  maxHeight: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

const Disclamer = styled('div')(() => ({
  background:'yellow',
  width: '100%',
  minHeight: '50px',
  maxHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '20px',
}))

const HowTo = styled('div')(() => ({
  background:'grey',
  width: '100%',
  minHeight: '50px',
  maxHeight: '250px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '20px',
}))

RechargeStationPayd.propTypes = {
  station: PropTypes.object, 
  onCancel: PropTypes.func,
  onClickContinue: PropTypes.func,
}

export default function RechargeStationPayd({
  station, 
  onCancel, 
  onClickContinue
}) {
  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StationStyle>
            <Typography variant='h3' sx={{ mb:2, pb:2 }} color='primary'>
              Dados da recarga - {station.name}
            </Typography>
            <Disclamer sx={{mb:2, pb:2 }}>
              <Typography variant='h8' >
                Nesta estação o custo do serviço de recarga sera de R${station.tax} pelo Kw/h, Você deseja continuar?.
              </Typography>
            </Disclamer>
            <HowTo >
              <Typography variant='h6'  >
                Reserva inicial de R$ 100,00 para iniciar a recarga. O estorno do valor não utilizado será feito após o término da recarga podendo ocorrer em até 48 horas.
              </Typography>
            </HowTo>
          </StationStyle>
          <Button 
            size='medium' 
            variant='contained' 
            color="secondary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onClickContinue}
          >
            Continuar
          </Button>

          <Button 
            size='medium' 
            variant='outlined' 
            color="primary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onCancel}
            >
            X Cancelar
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}
// @mui
import { styled } from '@mui/material/styles'
import { Container } from '@mui/material'
// components
import Page from '../../components/Page'
// sections
import HomeNavbar from '../../sections/public/home/HomeNavbar'
import HomeFooter from '../../sections/public/home/HomeFooter'

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 64

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 14,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 14,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))



// ----------------------------------------------------------------------

function ContactForm() {
  return (
    <RootStyle>
      <HomeNavbar/>
      <MainStyle>
        <Page title="contact">
          <Container maxWidth="xxl">
            <HomeFooter/>
          </Container>
        </Page>
      </MainStyle>
    </RootStyle>
    
  )
}

export default ContactForm

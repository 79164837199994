import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import RechargeStationSelect from '../../sections/app/charge/RechargeStationSelect'
import RechargeStationUnlock from '../../sections/app/charge/RechargeStationUnlock'
import RechargeStationPayd from '../../sections/app/charge/RechargeStationPayd'
import RechargeStationFree from '../../sections/app/charge/RechargeStationFree'
import RechargeStatus from '../../sections/app/charge/RechargeStatus'
import RechargeConect from '../../sections/app/charge/RechargeConect'

import { withStore } from '../../_store/StoreContext'

// ----------------------------------------------------------------------

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AppCharge(store) {
  const navigate = useNavigate()
  const st = store.store
  const { stations, chargeOngoing } = st

  const query = useQuery();
  const stationUUID = query.get("station")
  const orderID = query.get("order_id")

  const [state, setState] = useState('stationSelect')
  const [station, setStation] = useState(null)
  const [code, setCode] = useState(null)

  useEffect(() => {
    tick()
  }, []);

  useEffect(() => {
    _initializer()
      .catch(console.error)

    const interval = setInterval(() => tick(), 10*1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _initializer = async () => {
    const sto = await st.stationsGetAll()
    await st.chargesAll()
    const chargeWaiting = await st.getChargeWaiting()
    const chargeOngoing = await st.getChargeOngoing()
    if(chargeWaiting){
      setState('conect')
      st.setChargeState('conect')
    }
    if(chargeOngoing){
      setState('status')
      st.setChargeState('status')
    }

    if (orderID) {
      setState('conect')
      st.setChargeState('conect')
    }

    if (stationUUID) {
      const _station = sto.find(sto => sto.uuid === stationUUID)
      setStation(_station)
      handleStationSelect(_station)
    }
  }

  const tick = async () => {
    await st.chargesAll()
    const chargeOngoingTick = await st.getChargeOngoing()
    const chargeWaitingTick = await st.getChargeWaiting()
    const chargeStateTick = await st.getChargeState()

    if(chargeWaitingTick != null){
      setState('conect')
      st.setChargeState('conect')
    }

    if(chargeOngoingTick != null){
      setState('status')
      st.setChargeState('status')
    }

    if(chargeStateTick ==='conect' && chargeWaitingTick === null && chargeOngoingTick === null){
      setState('stationSelect')
      st.setChargeState('stationSelect')
    }
  
    if(chargeStateTick ==='status' && chargeOngoingTick === null && chargeOngoingTick === null){
      setState('stationSelect')
      st.setChargeState('stationSelect')
    }
  }

  const handleCancel = async () => {
    setStation(null)
    setState('stationSelect')
    st.setChargeState('stationSelect')
    navigate('/app/charge', { replace: true })
  }

  const handleStationSelect = (_station = null) => {
    if (!station && !_station) {
      return
    }

    const payment = station ? station.payment : _station.payment

    switch (payment) {
      case 'offline':
        setState('stationFree')
        st.setChargeState('stationFree')
        break
      case 'paid':
        setState('stationPayd')
        st.setChargeState('stationPayd')
        break
      default:
        setState('stationUnlock')
        st.setChargeState('stationUnlock')
        break
    }
  }

  const handlePaymentContinue = async () => {
    const checkout = await st.checkouts(station.uuid)
    window.location.href = checkout.paymentURL
  }

  const handleUnlock = async (pCode) => {
    await st.chargeStart(station.uuid,  code || pCode )
    await st.chargesAll()
    setState('conect')
    st.setChargeState('conect')
  }

  const handleStatus = async () => {
    await st.chargesAll()
    setState('status')
    st.setChargeState('status')
  }

  const handleStopCharge = async () => {
    await st.chargeStop(chargeOngoing.uuid)
    setState('stationSelect')
    st.setChargeState('stationSelect')
  }

  const handleFree = async () => {
    setStation(null)
    setState('stationSelect')
    st.setChargeState('stationSelect')
  }

  return (
  state ==='status' &&
  <RechargeStatus chargeOngoing={chargeOngoing} onClickStopCharge={handleStopCharge}/> ||
  state ==='stationSelect' && 
  <RechargeStationSelect 
    stations={stations}
    station={station} 
    setStation={setStation}
    onClickContinue={handleStationSelect}
    onCancel={handleCancel}
  /> ||
  state ==='stationUnlock' && 
  <RechargeStationUnlock
    station={station} 
    setCode={setCode}
    onClickUnlock={handleUnlock}  
    onCancel={handleCancel}
  /> ||
  state ==='stationPayd' &&
  <RechargeStationPayd 
    station={station}
    onClickContinue={handlePaymentContinue}
    onCancel={handleCancel}
  /> ||
  state ==='stationFree' &&
  <RechargeStationFree 
    station={station}
    onClickContinue={handleFree}
  /> ||
  state ==='conect' &&
  <RechargeConect onClickOK={handleStatus}/>
  )
}

export default withStore(AppCharge)

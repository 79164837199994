// @mui
import { styled } from '@mui/material/styles'
import { Grid, Stack } from '@mui/material'

// ----------------------------------------------------------------------
const ContentStyle= styled('div')(({ theme }) => ({
  background: 'linear-gradient(97deg, rgba(84,58,203,1) 0%, rgba(84,237,138,1) 100%)',
  color:'#ffffff',
  minHeight: '807px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 2),
  marginBottom:'32px',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  }
}))

const Cards= styled('div')(() => ({
  justifyContent: "center",
  alignSelf: "stretch",
  borderRadius: "30px",
  backgroundColor: "var(--White, #FFF)",
  maxWidth: "291px",
  height: "156px",
  color: "#36209E",
  padding: "36px 48px",
}))

const TextCards = styled('div')(()=>({
  color: '#36209E',
  fontFamily: 'Ubuntu',
  textAlign:'left',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '140%', /* 28px */
}))

// ----------------------------------------------------------------------

export default function IntroNew() {
  return (
    <ContentStyle >
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <TextCards variant="h3" sx={{color:'#FFF', maxWidth:529, fontSize:40, textAlign:'left', paddingBottom:4}}>
              Gerencie e rentabilize seus carregadores elétricos
            </TextCards>
            <TextCards sx={{color:'#FFF', maxWidth:529, fontSize:20, textAlign:'left'}} >
              Alugue ou compre carregadores e garanta o retorno do Investimento usando nossa plataforma.
            </TextCards>
          </Stack>          
        </Grid>
        <Grid item sx={{marginTop:'16px', marginBottom:'16px'}}>
          <img src="/static/illustrations/station_car_map.png" alt="intro" />
        </Grid>
        <Grid container sx={{maxWidth:'1285px'}} direction="row" justifyContent="space-around" alignItems="center">
          <Grid item>
            <Cards sx={{marginTop:'16px'}}>
              <TextCards>
                Aplicativo focado no carregamento para motoristas
              </TextCards>
            </Cards>
          </Grid>
          <Grid item>
            <Cards sx={{marginTop:'16px'}}>
              <TextCards>
                Configure o quanto você quer cobrar pela energia elétrica
              </TextCards>
            </Cards>
          </Grid>
          <Grid item>
            <Cards sx={{marginTop:'16px'}}>
              <TextCards>
                Gestão da capacidade de rede
              </TextCards>
            </Cards>
          </Grid>
        </Grid>
      </Grid>
    </ContentStyle>
  )
}

/* eslint-disable */
import React from 'react'
import { history, fetchWrapper} from '../_helpers'

const StoreContext = React.createContext({})
const { Provider, Consumer } = StoreContext

const baseUrl = 'https://api.conectave.com.br'

const withStore = BaseComponent => {
  return class extends React.Component {
    static displayName = `withStoreContext(${BaseComponent.displayName})`

    render () {
      return (
        <Consumer>
          {context => {
            return (
              <BaseComponent {...this.props} store={context} />
            )
          }}
        </Consumer>
      )
    }
  }
}

class StoreContextProvider extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      user: JSON.parse(localStorage.getItem('user')) || null,
      token: JSON.parse(localStorage.getItem('token')) || '',
      charges: [],
      chargeState: "stationSelect",
      chargeOngoing: null,
      chargeWaiting: null,
      stations: [],
      paymentURL: null,
      error: null,
      ...this.contextFunctions,
    }
  }

  get contextFunctions () {
    return {
      register: this.regiter,
      login: this.login,  
      logout: this.logout,
      stationsGetAll: this.stationsGetAll,
      chargesHistory: this.chargesHistory,
      chargesAll: this.chargesAll,
      chargeStart: this.chargeStart,
      chargeStop: this.chargeStop,
      getChargeWaiting: this.getChargeWaiting,
      getChargeOngoing: this.getChargeOngoing,
      setChargeState: this.setChargeState,
      getChargeState: this.getChargeState,
      checkouts: this.checkouts,
    }
  }

  regiter = async (values) => {
    try {
      const[ areaCode, _phone ] = values.phone.split("(").join("").split(")")
      
      values = {
        ...values,
        countryCode: "55",
        areaCode,
        phone: _phone,
      }

      console.log('values', values)

      await fetchWrapper.post('', `${baseUrl}/accounts/register`, { ...values, "acceptTerms": true })

      this.setState({
        error: null,
      })

      history.navigate('/')
    }
    catch (err) {

      this.setState({
        error: err
      })

      console.log('login - ERROR', err)
    }
  }

  login = async (email, password) => {
    try {
      const userProfile = await fetchWrapper.post('', `${baseUrl}/accounts/authenticate`, { email, password })
  
      this.setState({
        error: null,
        user: userProfile,
        token: userProfile.token
      })
      localStorage.setItem('token', JSON.stringify(userProfile.token))
      localStorage.setItem('user', JSON.stringify(userProfile))

      history.navigate('/app')
    }
    catch (err) {

      this.setState({
        error: err
      })
      
      history.navigate('/')
      console.log('login - ERROR', err)
    }
  }

  logout = () => {
    try {
      this.setState({
        user: null,
        token:'',
        stations:[],
        charges:[],
        chargeOngoing: null,
        chargeWaiting: null,
        chargeState: "stationSelect",
        paymentURL: null,
        error: null,
      })
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      history.navigate('/')
    }
    catch (err) {
      console.log('login - ERROR', err)
    }
  }

  stationsGetAll = async () => {
    try {
      const stations = await fetchWrapper.get(this.state.token, `${baseUrl}/stations`)
    
      this.setState({
        stations: stations.filter(station => station.status === 'available')
      })

      return stations
    }
    catch (err) {
      this.logout()
      console.log('stationsGetAll - ERROR', err)
    }
  }

  chargesHistory = async () => {
    try{
      const payload = await fetchWrapper.get(this.state.token, `${baseUrl}/charges/history`)

      this.setState({
        charges: payload.charges,
      })

      return payload
    }
    catch (err) {
      console.log('userCharges - ERROR', err)
    }
  }

  chargesAll = async () => {
    try{
      const date = new Date(Date.now())
      const charges = await fetchWrapper.get(this.state.token, `${baseUrl}/charges?month=${date.getMonth()+1}&year=${date.getFullYear()}`)

      const chargeOngoing = charges.find(charge => charge.status === "ongoing")
    
      if(chargeOngoing) {
        console.log('chargeOngoing - store', chargeOngoing)
        this.setState({
          chargeOngoing: chargeOngoing,
        })
      }else{
        this.setState({
          chargeOngoing: null,
        })
      }

      const chargeWaiting = charges.find(charge => charge.status === "waiting")
      if(chargeWaiting) {
        this.setState({
          chargeWaiting: chargeWaiting,
        })
      }else{
        this.setState({
          chargeWaiting: null,
        })
      }

      this.setState({
        charges: charges,
      })
    }
    catch (err) {
      this.logout()
      console.log('userCharges - ERROR', err)
    }
  }

  getChargeState = async () => {
    return this.state.chargeState
  }

  getChargeWaiting = async () => {
    return this.state.chargeWaiting
  }

  getChargeOngoing = async () => {
    return this.state.chargeOngoing
  }

  chargeStart = async (stationUUID, code) => {
    try{
      await fetchWrapper.post(this.state.token, `${baseUrl}/charges`, {code, stationUUID, userUUID: this.state.user.uuid})
      this.setState({
        chargeOngoing: true,
      })
    }
    catch (err) {
      console.log('stationUnlock - ERROR', err)
    }
    
  }

  chargeStop = async (chargeUUID) => {
    try{
      await fetchWrapper.put(this.state.token, `${baseUrl}/charges/stop`, {chargeUUID})
      this.setState({
        chargeOngoing: null,
      })
    }catch (err) {
      console.log('userChargeStop - ERROR', err)
    }
  }

  checkouts = async (stationUUID) => {
    try{
      const checkoutResponse = await fetchWrapper.post(this.state.token, `${baseUrl}/checkouts`, {stationUUID,  accountUUID: this.state.user.uuid})
      return checkoutResponse
    }catch (err) {
      this.logout()
      console.log('checkouts - ERROR', err)
    }
  }

  setChargeState = async (state) => {
    this.setState({
      chargeState: state,
    })
  }

  getChargeState = async () => {
    return this.state.chargeState
  }

  getChargeWaiting = async () => {
    return this.state.chargeWaiting
  }

  getChargeOngoing = async () => {
    return this.state.chargeOngoing
  }

  render () {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

export {
  StoreContextProvider,
  withStore,
}
